import React, { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import '../styles/style.css';

type MasteringFormData = {
    name: string;
    email: string;
    link: string;
    details?: string;
    'bot-field'?: string;
    'form-name'?: string;
};

const Mastering: React.FC = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm<MasteringFormData>({
        defaultValues: {
            name: '',
            email: '',
            link: '',
            details: '',
            'bot-field': '',
            'form-name': 'mastering-upload',
        },
    });
    const [submitted, setSubmitted] = useState(false);
    const [paymentRequired, setPaymentRequired] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [trackCount, setTrackCount] = useState(1);
    const [isRush, setIsRush] = useState(false);

    const onSubmit: SubmitHandler<MasteringFormData> = async (data) => {
        // console.log('Form data:', data);
        // setSubmitted(true);
        // setPaymentRequired(true);
        // setErrorMessage(null);
        // reset();
        // Uncomment below for Netlify once deployed
        
        const form = new FormData();
        form.append('form-name', data['form-name'] || 'mastering-upload');
        form.append('name', data.name);
        form.append('email', data.email);
        form.append('link', data.link);
        form.append('details', data.details || '');
        form.append('bot-field', data['bot-field'] || '');
    
        const encodedData = new URLSearchParams(form as any).toString();
        console.log('Form data being sent:', encodedData);
    
        try {
          const response = await fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encodedData,
          });
          if (response.ok) {
            console.log('Form submitted successfully');
            setSubmitted(true);
            setPaymentRequired(true);
            setErrorMessage(null);
            reset();
          } else {
            const errorText = await response.text();
            console.error('Form submission failed with status:', response.status, 'Response:', errorText);
            setErrorMessage(`Submission failed: ${response.status} - ${errorText}`);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          setErrorMessage('An error occurred while submitting the form.');
        }
        
    };

    return (
        <div>
            <header>
                <Link to="/"><img src={process.env.PUBLIC_URL + '/img/cooltext447210041642359.png'} alt="Acousma Audio" className="header-logo" /></Link>
                <nav>
                    <Link to="/music"><img src={process.env.PUBLIC_URL + '/img/cooltext447211158564284.png'} alt="Music" /></Link>
                    <Link to="/mastering"><img src={process.env.PUBLIC_URL + '/img/cooltext447211180201130.png'} alt="Mastering" /></Link>
                    <Link to="/shop"><img src={process.env.PUBLIC_URL + '/img/cooltext447211106806544.png'} alt="Shop" /></Link>
                    <Link to="/user"><img src={process.env.PUBLIC_URL + '/img/cooltext447211214587068.png'} alt="User" /></Link>
                </nav>
            </header>
            <main>
                <h2>Mastering</h2>
                <h3>Service</h3>
                <p>
                    Like my sound? I'll master your track! General mastering—polished, punchy, and ready to push air.
                    Singles, EPs, or albums—your waves, my gear. Turnaround: 3-5 business days (ASAP when possible).
                </p>
                <h3>Gear</h3>
                <h4>Hardware</h4>
                <ul>
                    <li>Converter: RME ADI-2/4 Pro SE</li>
                    <li>Juenger Audio Accent 1</li>
                    <li>Bettermaker EQ232P MKII</li>
                    <li>Bettermaker Mastering Compressor</li>
                    <li>Bettermaker Darthlimiter</li>
                </ul>
                <h4>Software</h4>
                <ul>
                    <li>Leapwing DynOne</li>
                    <li>FabFilter Pro-Q, Kirchhoff EQ</li>
                </ul>
                <h3>Preparing Your Mixes</h3>
                <p>
                    Export at 32-bit (if possible)—allows me to avoid clipping. 16/24-bit is great too, just note that if it's clipping it will be baked in.
                    Mix bus processing (EQ, compression) is fine if it’s your sound; disable limiters so I’ve got room to work with the dynamic range.
                </p>
                <h3>How This Works</h3>
                <p>
                    Prepare your tracks, and upload them to a filehosting service of your choice. Fill out the form below, and make your payment.
                    Make sure to include in the Mastering Notes any requirments you have, or things you want me to keep in mind. 
                    I'll master your mixes, and send you a link to download them to your email. Unlimited resvisions at my discretion. 
                    <br />
                    <br />
                    Feel free to message me on<a href="https://bsky.app/profile/photons.acousma.audio" className="social-link">Bluesky</a>if you have any questions.  
                </p>
                <h4>Pricing</h4>
                <ul>
                    <li>All projects: $50/track</li>
                    <li>Rush (24-48 hours): +$15/track</li>
                </ul>
                <h3>Submit Your Project</h3>
                <form name="mastering-upload" onSubmit={handleSubmit(onSubmit)} data-netlify="true" data-netlify-honeypot="bot-field">
                    <input
                        type="hidden"
                        {...register('form-name')}
                        value="mastering-upload"
                        aria-hidden="true"
                    />
                    <input
                        type="text"
                        {...register('bot-field')}
                        className="bot-field"
                        style={{ display: 'none' }}
                        aria-label="Leave this field empty"
                        aria-hidden="true"
                    />
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        placeholder="Your name"
                        title="Enter your name"
                        {...register('name', { required: 'Name is required' })}
                        className="form-input"
                    />
                    {errors.name && <p className="error">{errors.name.message}</p>}
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Your email"
                        title="Enter your email address"
                        {...register('email', { required: 'Email is required', pattern: { value: /\S+@\S+\.\S+/, message: 'Invalid email' } })}
                        className="form-input"
                    />
                    {errors.email && <p className="error">{errors.email.message}</p>}
                    <label htmlFor="link">Upload Link:</label>
                    <input
                        type="url"
                        id="link"
                        placeholder="https://mega.nz/..."
                        title="Enter the URL to your audio files"
                        {...register('link', { required: 'Upload link is required', pattern: { value: /^https?:\/\/.+$/, message: 'Invalid URL' } })}
                        className="form-input"
                    />
                    {errors.link && <p className="error">{errors.link.message}</p>}
                    <label htmlFor="details">Mastering Notes:</label>
                    <textarea
                        id="details"
                        rows={4}
                        placeholder="E.g., '-6 LUFS, keep bass punchy'"
                        title="Additional notes for mastering (optional)"
                        {...register('details')}
                        className="form-input"
                    />
                    <label htmlFor="track-count">Number of Tracks:</label>
                    <input
                        type="number"
                        id="track-count"
                        value={trackCount}
                        onChange={(e) => setTrackCount(Math.max(1, Number(e.target.value)))}
                        min="1"
                        className="form-input"
                    />
                    <label htmlFor="rush">Rush (24-48 hours):</label>
                    <input
                        type="checkbox"
                        id="rush"
                        checked={isRush}
                        onChange={(e) => setIsRush(e.target.checked)}
                        className="form-checkbox"
                    />
                    <button type="submit" className="form-button">Submit</button>
                </form>
                {submitted && (
                    <>
                        <p>Your submission has been received. Please complete payment for mastering.</p>
                        {paymentRequired && (
                            <PayPalScriptProvider options={{ clientId: 'AZGtGF7FAkQJy7JRQ3dzWYWUdbDx2f2vTFE_5mLPXLu4ov20iIlNt4eu5rwYwvjH6Mwl2WI7sPsvmiFA' }}>
                                <PayPalButtons
                                    createOrder={(data, actions) => {
                                        const total = trackCount * 50 + (isRush ? trackCount * 15 : 0);
                                        return actions.order.create({
                                            intent: 'CAPTURE',
                                            purchase_units: [
                                                {
                                                    amount: {
                                                        currency_code: 'USD',
                                                        value: total.toFixed(2),
                                                    },
                                                },
                                            ],
                                        });
                                    }}
                                    onApprove={(data, actions) => {
                                        return actions.order!.capture().then((details) => {
                                            setErrorMessage('Payment completed! Mastered files will be sent soon.');
                                            setPaymentRequired(false);
                                        });
                                    }}
                                />
                            </PayPalScriptProvider>
                        )}
                    </>
                )}
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </main>
            <footer>
                Copyright 2025 Acousma Audio. All rights reserved.
                <div>
                    <a href="https://bsky.app/profile/photons.acousma.audio" className="social-link">Bluesky</a> |
                    <a href="https://www.youtube.com/@pho7ons" className="social-link">YouTube</a> |
                    <a href="https://photons.bandcamp.com" className="social-link">Bandcamp</a> |
                    <a href="https://soundcloud.com/pho7ons" className="social-link">SoundCloud</a> |
                    <a href="https://open.spotify.com/artist/6JnQBGJne2QAZBVhXIwyWa?si=nrNddhopRBuqARIWz7Ec4w" className="social-link">Spotify</a> |
                    <a href="https://music.apple.com/us/artist/photons/1489893022" className="social-link">Apple Music</a> |
                    <a href="https://amazon.com/music/player/artists/B001H1BREY/photons?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_rfn9O8rGesB5ZEyiCFg3FXl2J" className="social-link">Amazon Music</a>
                </div>
            </footer>
        </div>
    );
};

export default Mastering;