import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import '../styles/style.css';

const Music: React.FC = () => {
    return (
        <div>
            <header>
                <Link to="/"><img src={process.env.PUBLIC_URL + '/img/cooltext447210041642359.png'} alt="Acousma Audio" className="header-logo" /></Link>
                    <nav>
                        <Link to="/music"><img src={process.env.PUBLIC_URL + '/img/cooltext447211158564284.png'} alt="Music" /></Link>
                        <Link to="/mastering"><img src={process.env.PUBLIC_URL + '/img/cooltext447211180201130.png'} alt="Mastering" /></Link>
                        <Link to="/shop"><img src={process.env.PUBLIC_URL + '/img/cooltext447211106806544.png'} alt="Shop" /></Link>
                        <Link to="/user"><img src={process.env.PUBLIC_URL + '/img/cooltext447211214587068.png'} alt="User" /></Link>
                    </nav>
            </header>
            <main>
                <h2>Presenting</h2>
                <a href="https://hyperfollow.com/photons"><img src={process.env.PUBLIC_URL + 'img/photons color drop - bigger.png' } alt="Photons" className="music-photons"/></a>
                <h3>Liminal Light</h3>
                <iframe className="iframe-bandcamp" title="Liminal Light Player" src="https://bandcamp.com/EmbeddedPlayer/album=4106919858/size=large/bgcol=333333/linkcol=9a64ff/transparent=true/" seamless></iframe>
                <h3>Lightmaker</h3>
                <iframe className="iframe-bandcamp" title="Lightmaker Player" src="https://bandcamp.com/EmbeddedPlayer/album=1580536190/size=large/bgcol=333333/linkcol=fe7eaf/transparent=true/" seamless></iframe>    
            </main>
            <footer>
                Copyright 2025 Acousma Audio. All rights reserved.
                <div>
                    <a href="https://bsky.app/profile/photons.acousma.audio" className="social-link">Bluesky</a> |
                    <a href="https://www.youtube.com/@pho7ons" className="social-link">YouTube</a> |
                    <a href="https://photons.bandcamp.com" className="social-link">Bandcamp</a> |
                    <a href="https://soundcloud.com/pho7ons" className="social-link">SoundCloud</a> |
                    <a href="https://open.spotify.com/artist/6JnQBGJne2QAZBVhXIwyWa?si=nrNddhopRBuqARIWz7Ec4w" className="social-link">Spotify</a> |
                    <a href="https://music.apple.com/us/artist/photons/1489893022" className="social-link">Apple Music</a> |
                    <a href="https://amazon.com/music/player/artists/B001H1BREY/photons?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_rfn9O8rGesB5ZEyiCFg3FXl2J" className="social-link">Amazon Music</a>
                </div>
            </footer>
        </div>
    );
};

export default Music;