import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Music from './components/Music';
import Mastering from './components/Mastering';
import Shop from './components/Shop';
import AcousmaUser from './components/AcousmaUser';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/music" element={<Music />} />
        <Route path="/mastering" element={<Mastering />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/user" element={<AcousmaUser />} />
        
        {/* Add more routes later (e.g., /mastering, /shop) */}
      </Routes>
    </Router>
  );
};

export default App;