import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import '../styles/style.css';

const Shop: React.FC = () => {
    return (
        <div>
            <header>
                <Link to="/"><img src={process.env.PUBLIC_URL + '/img/cooltext447210041642359.png'} alt="Acousma Audio" className="header-logo" /></Link>
                    <nav>
                        <Link to="/music"><img src={process.env.PUBLIC_URL + '/img/cooltext447211158564284.png'} alt="Music" /></Link>
                        <Link to="/mastering"><img src={process.env.PUBLIC_URL + '/img/cooltext447211180201130.png'} alt="Mastering" /></Link>
                        <Link to="/shop"><img src={process.env.PUBLIC_URL + '/img/cooltext447211106806544.png'} alt="Shop" /></Link>
                        <Link to="/user"><img src={process.env.PUBLIC_URL + '/img/cooltext447211214587068.png'} alt="User" /></Link>
                    </nav>
            </header>
            <main>
                <h2>Shop</h2>
                <p>
                    The Shop is currently under construction—sample packs coming soon!
                </p>
                <h3>Photons on Bandcamp</h3>
                <p>
                    Grab my tunes now at <a href="https://photons.bandcamp.com/" target="_blank" rel="noopener" className="bandcamp-link">photons.bandcamp.com</a>!
                </p>
            </main>
            <footer>
                Copyright 2025 Acousma Audio. All rights reserved.
                <div>
                    <a href="https://bsky.app/profile/photons.acousma.audio" className="social-link">Bluesky</a> |
                    <a href="https://www.youtube.com/@pho7ons" className="social-link">YouTube</a> |
                    <a href="https://photons.bandcamp.com" className="social-link">Bandcamp</a> |
                    <a href="https://soundcloud.com/pho7ons" className="social-link">SoundCloud</a> |
                    <a href="https://open.spotify.com/artist/6JnQBGJne2QAZBVhXIwyWa?si=nrNddhopRBuqARIWz7Ec4w" className="social-link">Spotify</a> |
                    <a href="https://music.apple.com/us/artist/photons/1489893022" className="social-link">Apple Music</a> |
                    <a href="https://amazon.com/music/player/artists/B001H1BREY/photons?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_rfn9O8rGesB5ZEyiCFg3FXl2J" className="social-link">Amazon Music</a>
                </div>
            </footer>
        </div>
    );
};

export default Shop;