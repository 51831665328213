import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  user: {
    uid?: string;
    email?: string | null | undefined;
    displayName?: string | null | undefined;
  } | null;
  tenant: string | null;
}

const initialState: AuthState = {
  user: null,
  tenant: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{
      uid: string;
      email?: string | null | undefined;
      displayName?: string | null | undefined;
    } | null>) => {
      state.user = action.payload;
    },
    setTenant: (state, action: PayloadAction<string | null>) => {
      state.tenant = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
      state.tenant = null;
    },
  },
});

export const { setUser, setTenant, clearUser } = authSlice.actions;
export default authSlice.reducer;