import React from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import '../styles/style.css';

const Home: React.FC = () => {
  return (
    <div>
      <header>
        <Link to="/"><img src={process.env.PUBLIC_URL + '/img/cooltext447210041642359.png'} alt="Acousma Audio" className="header-logo" /></Link>
          <nav>
            <Link to="/music"><img src={process.env.PUBLIC_URL + '/img/cooltext447211158564284.png'} alt="Music" /></Link>
            <Link to="/mastering"><img src={process.env.PUBLIC_URL + '/img/cooltext447211180201130.png'} alt="Mastering" /></Link>
            <Link to="/shop"><img src={process.env.PUBLIC_URL + '/img/cooltext447211106806544.png'} alt="Shop" /></Link>
            <Link to="/user"><img src={process.env.PUBLIC_URL + '/img/cooltext447211214587068.png'} alt="User" /></Link>
          </nav>
      </header>
      <main>
        <h1>Waves for Air-Pushers</h1>
        <p className="home-intro">
          Acousma Audio is the music label for Photons, I also provide mastering services.
        </p>
        <h2>News</h2>
        <h3>02-28-25</h3>
        <div>
          New Photons EP, Liminal Light, is out on Bandcamp and SoundCloud as of 02/25/2025. As of today, there is also this brand spankn' new website!
          Handcrafted by yours truly, goodbye wysiwyg!
          <br /><br /><strong>Changelog v0.01:</strong>
          <ul>
            <li>Locked down starry tiling—no more cosmic drift.</li>
            <li>Fixed shifting with a scrollbar tweak.</li>
            <li>Added bordering and solid body color.</li>
            <li>Centered Photons image.</li>
            <li>Intro text alignment.</li>
            <li>Refreshed colors—blues and pink/purple pop.</li>
            <li>Spaced out footer—cleaner look.</li>
            <li>Updated the shop with bandcamp link, etc.</li>
            <li>Site launched!</li>
          </ul>
          <em>“Retro web, freshly patched.”</em>
        </div>
        <h3>11-14-23</h3>
        <p>
          <strong>Changelog v0.00:</strong>
        </p>
        <ul><li>Today I started the build for this website.</li></ul>
        <div>
          Planning notes:
          <ul>
            <li>DTA (direct to audience) music distro</li>
            <li>Mastering Service upload and download form functionality</li>
            <li>Sample Packs</li>
            <li>Shop functionality</li>
            <li>User Area
              <ul>
                <li>Sign-up, Login, and Authentication</li>
                <li>Access to editable profile (user info)</li>
                <li>Purchase history</li>
                <li>Downloads for any music, samples, or masters</li>
              </ul>
            </li>
          </ul>
          Future Ambitions:
          <ul>
            <li>VST plugins</li>
            <li>Synth and music production hardware</li>
            <li>Music for video game</li>
            <li>Profit!</li>
          </ul>
        </div>
      </main>
      <footer>
        Copyright 2025 Acousma Audio. All rights reserved.
        <div>
          <a href="https://bsky.app/profile/photons.acousma.audio" className="social-link">Bluesky</a> |
          <a href="https://www.youtube.com/@pho7ons" className="social-link">YouTube</a> |
          <a href="https://photons.bandcamp.com" className="social-link">Bandcamp</a> |
          <a href="https://soundcloud.com/pho7ons" className="social-link">SoundCloud</a> |
          <a href="https://open.spotify.com/artist/6JnQBGJne2QAZBVhXIwyWa?si=nrNddhopRBuqARIWz7Ec4w" className="social-link">Spotify</a> |
          <a href="https://music.apple.com/us/artist/photons/1489893022" className="social-link">Apple Music</a> |
          <a href="https://amazon.com/music/player/artists/B001H1BREY/photons?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_rfn9O8rGesB5ZEyiCFg3FXl2J" className="social-link">Amazon Music</a>
        </div>
        <div className="home-snowflakes" aria-hidden="true">
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
          <div className="snowflake">❅</div>
          <div className="snowflake">❆</div>
        </div>
      </footer>
    </div>
  );
};

export default Home;