import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  verifyBeforeUpdateEmail,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  User,
  updatePassword,
} from 'firebase/auth';
import { auth } from '../firebase';
import '../styles/style.css';

type LoginFormData = { email: string; password: string };
type SignupFormData = { email: string; password: string; verifyPassword: string };
type ResetFormData = { email: string };
type UpdateEmailFormData = { currentPassword: string; newEmail: string };
type UpdatePasswordFormData = { currentPassword: string; newPassword: string; verifyNewPassword: string };

const AcousmaUser: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [activeForm, setActiveForm] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null); // Changed to "message" for both success/error

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      setActiveForm(null);
    });
    return () => unsubscribe();
  }, []);

  const loginForm = useForm<LoginFormData>();
  const signupForm = useForm<SignupFormData>();
  const resetForm = useForm<ResetFormData>();
  const updateEmailForm = useForm<UpdateEmailFormData>();
  const updatePasswordForm = useForm<UpdatePasswordFormData>();

  const toggleForm = (formId: string) => {
    setActiveForm(activeForm === formId ? null : formId);
    setMessage(null);
  };

  const onLogin: SubmitHandler<LoginFormData> = async (data) => {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      setMessage('Logged in successfully!');
    } catch (err: any) {
      setMessage(err.message);
    }
  };

  const onSignup: SubmitHandler<SignupFormData> = async (data) => {
    if (data.password !== data.verifyPassword) {
      setMessage('Passwords do not match');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
      // Optional: Send verification email after sign-up
      await sendEmailVerification(userCredential.user);
      setMessage('Sign-up successful! Please check your email to verify your account.');
      signupForm.reset();
    } catch (err: any) {
      setMessage(err.message);
    }
  };

  const onReset: SubmitHandler<ResetFormData> = async (data) => {
    try {
      await sendPasswordResetEmail(auth, data.email);
      setMessage('Password reset email sent! Check your inbox.');
      resetForm.reset();
    } catch (err: any) {
      setMessage(err.message);
    }
  };

  const onUpdateEmail: SubmitHandler<UpdateEmailFormData> = async (data) => {
    if (!user) return;
    try {
      const credential = EmailAuthProvider.credential(user.email!, data.currentPassword);
      await reauthenticateWithCredential(user, credential);
      await verifyBeforeUpdateEmail(user, data.newEmail);
      setMessage('Verification email sent to your new address. Please verify it to complete the update.');
      updateEmailForm.reset();
    } catch (err: any) {
      setMessage(err.message);
    }
  };

  const onUpdatePassword: SubmitHandler<UpdatePasswordFormData> = async (data) => {
    if (!user) return;
    if (data.newPassword !== data.verifyNewPassword) {
      setMessage('New passwords do not match');
      return;
    }
    try {
      const credential = EmailAuthProvider.credential(user.email!, data.currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, data.newPassword);
      setMessage('Password updated successfully!');
      updatePasswordForm.reset();
    } catch (err: any) {
      setMessage(err.message);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setMessage('Logged out successfully!');
    } catch (err: any) {
      setMessage(err.message);
    }
  };

  return (
    <div>
      <header>
        <Link to="/"><img src={process.env.PUBLIC_URL + '/img/cooltext447210041642359.png'} alt="Acousma Audio" className="header-logo" /></Link>
        <nav>
          <Link to="/music"><img src={process.env.PUBLIC_URL + '/img/cooltext447211158564284.png'} alt="Music" /></Link>
          <Link to="/mastering"><img src={process.env.PUBLIC_URL + '/img/cooltext447211180201130.png'} alt="Mastering" /></Link>
          <Link to="/shop"><img src={process.env.PUBLIC_URL + '/img/cooltext447211106806544.png'} alt="Shop" /></Link>
          <Link to="/user"><img src={process.env.PUBLIC_URL + '/img/cooltext447211214587068.png'} alt="User" /></Link>
        </nav>
      </header>
      <main>
        <h2>User Area</h2>
        {user ? (
          <div id="user-welcome">
            <h3>Welcome, {user.email}</h3>
            <p>Logged in! More features coming—profile, purchases, downloads.</p>
            <button className="auth-button" onClick={() => toggleForm('update-email-form')}>
              Update Email
            </button>
            <button className="auth-button" onClick={() => toggleForm('update-password-form')}>
              Update Password
            </button>
            <button className="auth-button" onClick={handleLogout}>Log Out</button>
            {activeForm === 'update-email-form' && (
              <form onSubmit={updateEmailForm.handleSubmit(onUpdateEmail)}>
                <label htmlFor="current-password-email">Current Password:</label>
                <input
                  type="password"
                  id="current-password-email"
                  {...updateEmailForm.register('currentPassword', { required: 'Current password is required' })}
                  placeholder="Current password"
                  title="Enter your current password"
                />
                {updateEmailForm.formState.errors.currentPassword && (
                  <p>{updateEmailForm.formState.errors.currentPassword.message}</p>
                )}
                <label htmlFor="new-email">New Email:</label>
                <input
                  type="email"
                  id="new-email"
                  {...updateEmailForm.register('newEmail', {
                    required: 'New email is required',
                    pattern: { value: /\S+@\S+\.\S+/, message: 'Invalid email' },
                  })}
                  placeholder="New email"
                  title="Enter your new email address"
                />
                {updateEmailForm.formState.errors.newEmail && (
                  <p>{updateEmailForm.formState.errors.newEmail.message}</p>
                )}
                <button className="auth-button" type="submit">Submit</button>
              </form>
            )}
            {activeForm === 'update-password-form' && (
              <form onSubmit={updatePasswordForm.handleSubmit(onUpdatePassword)}>
                <label htmlFor="current-password">Current Password:</label>
                <input
                  type="password"
                  id="current-password"
                  {...updatePasswordForm.register('currentPassword', { required: 'Current password is required' })}
                  placeholder="Current password"
                  title="Enter your current password"
                />
                {updatePasswordForm.formState.errors.currentPassword && (
                  <p>{updatePasswordForm.formState.errors.currentPassword.message}</p>
                )}
                <label htmlFor="new-password">New Password:</label>
                <input
                  type="password"
                  id="new-password"
                  {...updatePasswordForm.register('newPassword', { required: 'New password is required' })}
                  placeholder="New password"
                  title="Enter your new password"
                />
                {updatePasswordForm.formState.errors.newPassword && (
                  <p>{updatePasswordForm.formState.errors.newPassword.message}</p>
                )}
                <label htmlFor="new-password-verify">Verify New Password:</label>
                <input
                  type="password"
                  id="new-password-verify"
                  {...updatePasswordForm.register('verifyNewPassword', { required: 'Verification is required' })}
                  placeholder="Verify new password"
                  title="Re-enter your new password"
                />
                {updatePasswordForm.formState.errors.verifyNewPassword && (
                  <p>{updatePasswordForm.formState.errors.verifyNewPassword.message}</p>
                )}
                <button className="auth-button" type="submit">Submit</button>
              </form>
            )}
          </div>
        ) : (
          <div id="user-login-prompt">
            <h3>Login or Sign Up</h3>
            <p>Get started with Acousma Audio—user profiles under construction.</p>
            <button className="auth-button" onClick={() => toggleForm('login-form')}>Log In</button>
            <button className="auth-button" onClick={() => toggleForm('signup-form')}>Sign Up</button>
            <button className="auth-button" onClick={() => toggleForm('reset-form')}>Forgot Password?</button>
            {activeForm === 'login-form' && (
              <form onSubmit={loginForm.handleSubmit(onLogin)}>
                <label htmlFor="login-email">Email:</label>
                <input
                  type="email"
                  id="login-email"
                  {...loginForm.register('email', {
                    required: 'Email is required',
                    pattern: { value: /\S+@\S+\.\S+/, message: 'Invalid email' },
                  })}
                  placeholder="Your email"
                  title="Enter your email address"
                />
                {loginForm.formState.errors.email && <p>{loginForm.formState.errors.email.message}</p>}
                <label htmlFor="login-password">Password:</label>
                <input
                  type="password"
                  id="login-password"
                  {...loginForm.register('password', { required: 'Password is required' })}
                  placeholder="Your password"
                  title="Enter your password"
                />
                {loginForm.formState.errors.password && <p>{loginForm.formState.errors.password.message}</p>}
                <button className="auth-button" type="submit">Submit</button>
              </form>
            )}
            {activeForm === 'signup-form' && (
              <form onSubmit={signupForm.handleSubmit(onSignup)}>
                <label htmlFor="signup-email">Email:</label>
                <input
                  type="email"
                  id="signup-email"
                  {...signupForm.register('email', {
                    required: 'Email is required',
                    pattern: { value: /\S+@\S+\.\S+/, message: 'Invalid email' },
                  })}
                  placeholder="Your email"
                  title="Enter your email address"
                />
                {signupForm.formState.errors.email && <p>{signupForm.formState.errors.email.message}</p>}
                <label htmlFor="signup-password">Password:</label>
                <input
                  type="password"
                  id="signup-password"
                  {...signupForm.register('password', { required: 'Password is required' })}
                  placeholder="Your password"
                  title="Enter your password"
                />
                {signupForm.formState.errors.password && <p>{signupForm.formState.errors.password.message}</p>}
                <label htmlFor="signup-password-verify">Verify Password:</label>
                <input
                  type="password"
                  id="signup-password-verify"
                  {...signupForm.register('verifyPassword', { required: 'Verification is required' })}
                  placeholder="Verify your password"
                  title="Re-enter your password"
                />
                {signupForm.formState.errors.verifyPassword && (
                  <p>{signupForm.formState.errors.verifyPassword.message}</p>
                )}
                <button className="auth-button" type="submit">Submit</button>
              </form>
            )}
            {activeForm === 'reset-form' && (
              <form onSubmit={resetForm.handleSubmit(onReset)}>
                <label htmlFor="reset-email">Email:</label>
                <input
                  type="email"
                  id="reset-email"
                  {...resetForm.register('email', {
                    required: 'Email is required',
                    pattern: { value: /\S+@\S+\.\S+/, message: 'Invalid email' },
                  })}
                  placeholder="Your email"
                  title="Enter your email address"
                />
                {resetForm.formState.errors.email && <p>{resetForm.formState.errors.email.message}</p>}
                <button className="auth-button" type="submit">Send Reset Link</button>
              </form>
            )}
          </div>
        )}
        {message && <p style={{ color: message.includes('success') ? 'green' : 'red' }}>{message}</p>}
      </main>
      <footer>
        Copyright 2025 Acousma Audio. All rights reserved.
        <div>
          <a href="https://bsky.app/profile/photons.acousma.audio" className="social-link">Bluesky</a> |
          <a href="https://www.youtube.com/@pho7ons" className="social-link">YouTube</a> |
          <a href="https://photons.bandcamp.com" className="social-link">Bandcamp</a> |
          <a href="https://soundcloud.com/pho7ons" className="social-link">SoundCloud</a> |
          <a href="https://open.spotify.com/artist/6JnQBGJne2QAZBVhXIwyWa?si=nrNddhopRBuqARIWz7Ec4w" className="social-link">Spotify</a> |
          <a href="https://music.apple.com/us/artist/photons/1489893022" className="social-link">Apple Music</a> |
          <a href="https://amazon.com/music/player/artists/B001H1BREY/photons?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_rfn9O8rGesB5ZEyiCFg3FXl2J" className="social-link">Amazon Music</a>
        </div>
      </footer>
    </div>
  );
};

export default AcousmaUser;